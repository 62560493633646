import React from "react";

export default function About() {
  return (
    <>
      <div className="card">
        <div className="card-body" style={{ textAlign: "justify" }}>
          <h4>About Us</h4>
          <p>
            CGJOBSCHOOL is a platform that is mainly focused on helping those
            aspirants who are preparing for state-level of competition exams. We
            are providing a platform where all aspirants can compete with every
            competitor and they can see their preparation level and they can
            improve their preparation.
          </p>

          <h4>Vision</h4>
          <div>
            <span className="fw-bold fst-italic">
              "Our learning is strengthened by practice which makes us shine
              like gold"
            </span>
            <p>
              Whatever we have learned, it is necessary to keep testing it, or
              else it becomes dull like a rusted sword.
            </p>
          </div>

          <h4>Goal</h4>
          <p>
            Our goal is to provide more trustworthy materials for the
            preparation of all aspirants and for achieving it our team is
            continuously working on it.
          </p>
          <hr />
          <h6 className="text-center">
            <a href="mailto:cgjobschool@gmail.com">Contact Us</a>
          </h6>
        </div>
      </div>
    </>
  );
}
