import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import externalLinkService from "../services/externalLink";

export default function Home({ spinner, user }) {
  const [importantLinks, setImportantLinks] = useState([]);
  const [currentOpenings, setCurrentOpenings] = useState([]);
  useEffect(() => {
    (async () => {
      spinner.setLoading(true);
      const links = await externalLinkService.getLinks("important");
      if (links) setImportantLinks(links);
      const openings = await externalLinkService.getLinks("current-opening");
      if (openings) setCurrentOpenings(openings);
      spinner.setLoading(false);
    })();
  }, []);

  return (
    <>
      <div className="card mb-2">
        <div className="card-body text-center">
          <h3 className="text-uppercase">
            welcome to cgjobschool to fulfill your dreams
          </h3>
          <div className="fst-italic">
            "Our learning is strengthened by practice which makes us shine like
            gold"
          </div>
        </div>
      </div>
      {!user.isLoggedIn ? (
        <div className="card mb-2">
          <div className="card-body text-center">
            <Link to="/signin">
              {" "}
              JOIN FREE TEST SERIES FOR ALL CHHATTISGARHG GOVT. EXAMS LIKE
              CGPSC,CG-ACF,PATVARI,CGTET etc.{" "}
            </Link>
          </div>
        </div>
      ) : null}

      <div className="card mb-2">
        <div className="card-body text-center">
          <a href="https://blog.cgjobschool.in/" target="_self">
            {" "}
            <strong>STUDY MATERIALS</strong>{" "}
          </a>
        </div>
      </div>

      {importantLinks.length ? (
        <ExternalLink heading="Important links" data={importantLinks} />
      ) : null}
      {currentOpenings.length ? (
        <ExternalLink heading="Current openings" data={currentOpenings} />
      ) : null}
    </>
  );
}

function ExternalLink({ heading, data }) {
  return (
    <div className="card mb-2">
      <h4 className="card-header">{heading}</h4>
      {data.map((dt, index) => {
        return (
          <div key={index} className="card-body py-2 border-top">
            <div className="row align-items-center">
              <div className="col-7 fw-bold text-uppercase">{dt.title}</div>
              <div className="col-5 text-end">
                <Link
                  to={{ pathname: dt.link }}
                  target="_blank"
                  className="btn btn-outline-light text-primary"
                >
                  Click here
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
